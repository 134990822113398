/* eslint-disable react/prop-types */
import { Fragment } from "react";
import BtnCreateAccount from "@components/buttons/btnCreateAccount/component";
import CustomVideo from "@components/customVideo/component";
import parse from "html-react-parser";
import ButtonCustom from "@components/buttons/btnCustom/component";
import style from "./tabContent.module.scss";

type ComponentProps = {
  title?: string;
  idVideo?: string;
  button?: boolean;
  buttonCreateAccount?: boolean;
  btnUrl: string;
  btnText: string;
  description?: string;
  smallText?: string;
};

type TabContentProps = {
  data: ComponentProps;
};

const TabContent: React.FC<TabContentProps> = ({ data }) => {
  const srcIcon = "https://www-stg.magneto365.com/manager/wp-content/uploads/2022/09/arrow-right-4.svg";
  return (
    <Fragment>
      {data.title && <h2>{parse(data.title)}</h2>}
      {data.idVideo && <CustomVideo videoId={data.idVideo} />}
      {data.description && <p className={style.description}>{parse(data.description)}</p>}
      {data.button &&
        (data.buttonCreateAccount ? (
          <BtnCreateAccount
            classIcon="las la-angle-right"
            color="purple"
            fontSize={16}
            iconType="classIcon"
            iconRight
            text={data.btnText}
          />
        ) : (
          <ButtonCustom
            href={data.btnUrl}
            txt={data.btnText}
            customStyle={style.btnTabContent}
            icon={true}
            src={srcIcon}
          />
        ))}
      {data.smallText && <small className={style.smallText}>{parse(data.smallText)}</small>}
    </Fragment>
  );
};

export default TabContent;
