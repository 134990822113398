import React from "react";
import Image from "next/image";
import style from "./loader.module.scss";
import iconPlayer from "public/assets/icons/play-circle.svg";

type LoaderProps = {
  loading: boolean;
};

const Loader: React.FC<LoaderProps> = ({ loading }) => {
  return (
    <div className={`${style.containerLoader} coverIconPlay`}>
      {loading ? (
        <div className={style.loader} />
      ) : (
        <Image src={iconPlayer} alt="play video" width={70} height={70} className={style.iconPlayer} />
      )}
    </div>
  );
};

export default Loader;
