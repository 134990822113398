import Image from "next/image";
import React, { Fragment } from "react";
import styleBtn from "../button.module.scss";
import styles from "./bgCustom.module.scss";

type TypeCustomProps = {
  href: string;
  txt: string;
  icon?: boolean;
  iconClass?: string;
  src?: string;
  bgc?: string;
  txtColor?: string;
  iconAlignment?: string;
  customStyle?: string;
  click?: () => void;
};

const ButtonCustom: React.FC<TypeCustomProps> = ({
  href,
  txt,
  icon,
  iconClass,
  src,
  iconAlignment,
  bgc,
  txtColor,
  customStyle,
  click
}) => {
  return (
    <Fragment>
      <style jsx>{`
        .btnCustom {
          background: ${bgc};
          color: ${txtColor};
          flex-direction: ${iconAlignment};
        }
      `}</style>
      <a href={href} className={`${styleBtn.btn} ${styles.type5} btnCustom ${customStyle}`} onClick={click}>
        {src && icon ? <Image src={src} alt={txt} width={20} height={20} /> : ""}
        <span>{txt}</span>
        {icon && iconClass ? <i className={iconClass}></i> : ""}
      </a>
    </Fragment>
  );
};

export default ButtonCustom;
