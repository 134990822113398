/* eslint-disable react/prop-types */
import Image from "next/image";
import { Fragment } from "react";
import style from "./tab.module.scss";
import parse from "html-react-parser";
import classNames from "classnames";

type TabProps = {
  click: () => void;
  text: string;
  disable: boolean;
  icon: string;
  styleB2B?: boolean;
};

const Tab: React.FC<TabProps> = ({ click, text, disable, icon, styleB2B }) => {
  const altText = text.replaceAll(/<\/?b>/g, "");
  const styleTabProp = styleB2B == true;
  const logoSize = !styleTabProp ? 25 : 50;
  const styleTab = classNames(style.btnTab, {
    [style.btnTabB2C]: !styleTabProp,
    [style.btnTabB2B]: styleTabProp
  });
  return (
    <Fragment>
      <button onClick={click} className={styleTab} disabled={disable}>
        <span className={style.content}>
          {icon != "" ? (
            <span className={style.bgImage}>
              <Image src={icon} width={logoSize} height={logoSize} alt={altText} />
            </span>
          ) : null}
          <span className={style.tabText}>{parse(text)}</span>
        </span>
        {disable && styleB2B ? <i className="las la-angle-right"></i> : null}
      </button>
    </Fragment>
  );
};

export default Tab;
