import Image from "next/image";
import React, { Fragment } from "react";

type VideoCoverProps = {
  videoId: string;
};

const VideoCover: React.FC<VideoCoverProps> = ({ videoId }) => {
  return (
    <Fragment>
      <Image
        src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
        alt="Video Thumbnail"
        width={1280}
        height={720}
      />
    </Fragment>
  );
};

export default VideoCover;
