/* eslint-disable react/prop-types */
import { TFunction, withTranslation } from "next-i18next";
import { Fragment } from "react";
import style from "./spinner.module.scss";

type SpinLoadProps = {
  fontSize?: number;
  iconSrc?: string;
  t: TFunction;
};

const SpinLoad: React.FC<SpinLoadProps> = () => {
  return (
    <Fragment>
      <i className={`las la-sync ${style.spinLoad}`}></i>
    </Fragment>
  );
};

export default withTranslation()(SpinLoad);
