import React, { useState } from "react";
import style from "./customVideo.module.scss";
import Loader from "./loader/component";
import VideoCover from "./videoCover/component";
import VideoPlayer from "./videoPlayer/component";

type CustomVideoProps = {
  videoId: string;
};

const CustomVideo: React.FC<CustomVideoProps> = ({ videoId }) => {
  const [showVideoCover, setShowVideoCover] = useState(true);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setShowVideoCover(true);
    setShowVideoPlayer(true);
    setLoading(true);

    setTimeout(() => {
      setShowVideoCover(false);
      setLoading(false);
    }, 2000);
  };

  return (
    <div className={style.containerVideoYoutube} onClick={handleClick}>
      <Loader loading={loading} />
      {showVideoCover && <VideoCover videoId={videoId} />}
      {showVideoPlayer && <VideoPlayer videoId={videoId} />}
    </div>
  );
};

export default CustomVideo;
