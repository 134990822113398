import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./slider.module.scss";
import React, { MouseEventHandler, ReactNode } from "react";

type BaseProps = {
  onClick?: MouseEventHandler<HTMLSpanElement>;
  className?: string;
  customClass?: string;
};

type SliderProps = {
  children: ReactNode;
  customClassArrow?: string;
  customClassDots?: string;
  slidesToShow: number;
  slidesToScroll: number;
  centerMode?: boolean;
};

const SampleNextArrow: React.FC<BaseProps> = (props) => {
  const { className, onClick, customClass } = props;
  return <div className={`${className} ${style.arrow} ${customClass} ${style.arrowRight}`} onClick={onClick} />;
};

const SamplePrevArrow: React.FC<BaseProps> = (props) => {
  const { className, onClick, customClass } = props;
  return <div className={`${className} ${style.arrow} ${customClass} ${style.arrowLeft}`} onClick={onClick} />;
};

const SliderComponent: React.FC<SliderProps> = ({
  children,
  customClassArrow,
  customClassDots,
  slidesToShow,
  slidesToScroll,
  centerMode
}) => {
  const settings = {
    className: centerMode ? "centerMode" : "",
    centerMode: centerMode ? true : false,
    centerPadding: "0px",
    dots: true,
    dotsClass: `${customClassDots}`,
    infinite: true,
    autoplay: false, //TODO this property is true
    autoplaySpeed: 5000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    speed: 2000,
    initialSlide: 0,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow customClass={customClassArrow} />,
    prevArrow: <SamplePrevArrow customClass={customClassArrow} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default SliderComponent;
