type RestData = {
  restData: string;
};

type DataJsonValidateProps = {
  restInfo: RestData;
  restData: string;
};

const dataJsonValidate = ({ restInfo, restData }: DataJsonValidateProps) => {
  let dataJson;
  let dataValidate;

  try {
    if (restInfo && restData) {
      dataJson = JSON.parse(restData);

      if (dataJson && Array.isArray(dataJson) && dataJson.length > 0) {
        dataValidate = dataJson;
      }
    }
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  return {
    dataJson,
    dataValidate
  };
};

export default dataJsonValidate;
