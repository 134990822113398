/* eslint-disable react/prop-types */
import CustomVideo from "@components/customVideo/component";
import { Fragment } from "react";
import style from "./slideVideo.module.scss";

type SlideVideosProps = {
  video: string;
};

const SlideVideos: React.FC<SlideVideosProps> = ({ video }) => {
  return (
    <Fragment>
      <div className={style.slideVideo}>
        <CustomVideo videoId={video} />
      </div>
    </Fragment>
  );
};

export default SlideVideos;
