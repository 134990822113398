/* eslint-disable react/prop-types */
import style from "./btnCreateAccount.module.scss";
import Image from "next/image";
import { Fragment } from "react";
import useOpenWidgetCreateAccount from "@hooks/magnetoUi/useOpenWidgetToApply";
import SpinLoad from "./spinner/component";
import classNames from "classnames";
import { TFunction, withTranslation } from "next-i18next";

type BtnCreateAccountProps = {
  classIcon?: string;
  color?: "green" | "purple" | "blue";
  fontSize?: number;
  iconType: "img" | "classIcon" | "none";
  img?: string;
  iconRight?: true;
  text: string;
  t: TFunction;
};

const BtnCreateAccount: React.FC<BtnCreateAccountProps> = ({
  classIcon,
  color,
  fontSize,
  iconType,
  img,
  iconRight,
  text,
  t
}) => {
  const { signUpLoading, openCreateAccount } = useOpenWidgetCreateAccount();
  const styles = classNames(style.btnCreateAccount, {
    [style.iconRight]: iconRight,
    [style.purple]: color == "purple",
    [style.blue]: color == "blue",
    [style.green]: color == undefined
  });

  return (
    <Fragment>
      <div className={style.containerBtnCreateAccount}>
        <button onClick={openCreateAccount} disabled={signUpLoading == true} className={styles}>
          {signUpLoading == true ? (
            <SpinLoad />
          ) : (
            <Fragment>
              {(() => {
                switch (iconType) {
                  case "none":
                    return null;
                  case "classIcon":
                    return <i className={classIcon}></i>;
                  case "img":
                    return (
                      img && (
                        <Image src={img} alt={t<string>("genericTexts:altTexts.register")} width={25} height={25} />
                      )
                    );
                  default:
                    return null;
                }
              })()}
            </Fragment>
          )}
          <span style={{ fontSize: `${fontSize}px` }}>{text}</span>
        </button>
      </div>
    </Fragment>
  );
};

export default withTranslation()(BtnCreateAccount);
