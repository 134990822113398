import React from "react";

type VideoPlayerProps = {
  videoId: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoId }) => {
  return (
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&rel=0`}
      title="YouTube Video"
      frameBorder="0"
      allowFullScreen
      allow="autoplay"
    ></iframe>
  );
};

export default VideoPlayer;
